import {Injectable} from '@angular/core';
import {Product} from './product';
import {Products} from './mockProducts';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  getProducts(): Product[] {
    return Products;
  }

  getProductByTitle(title: string): Observable<Product> {
    return of(Products.find(product => product.title === title));
  }

  constructor() {
  }
}
