<div class="content">
  <mat-grid-list cols="{{nbCols}}" rowHeight="10:6"
                 gutterSize="5px">
    <mat-grid-tile *ngFor="let product of products" [class.selected]="product === selectedProduct"
                   (click)="onSelect(product)">
      <div class="overlayContent">
        <a routerLink="/detail/{{product.title}}">
        <img mat-card-image class="tile-image" src="{{product.media[0]}}" alt="{{product.title}}" >
        <div class="overlay">
          <div class="text">{{product.title|uppercase}}</div>
        </div>
        </a>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
