import {Product} from './product';

export const Products: Product[] = [
  {
    id: 8,
    title: 'La Cen',
    subtitle: '(Dokumentarfilm, 2021, Kuba / Deutschland, 77 Minuten, HD, Color, Stereo)',
    description: '',
    synopsis: '„La Cen“ ist das Porträt einer kleinen Stadt auf Kuba, die einst für das größte Bauprojekt ' +
      'Fidel Castros errichtet wurde. Dieses „Bauwerk des Jahrhunderts“ sollte einmal der erste Atomreaktor in ' +
      'der Karibik werden. Vier Protagonisten geben einen Einblick in ihren Alltag in der „Nuklearstadt“, zwischen ' +
      'den verstaubten Träumen eines technischen Fortschritts und den Sehnsüchten der Gegenwart. Eine bittersüße ' +
      'kubanische Geschichte.',
    awards: [],
    staff: ['Regie, Buch und Bildgestaltung: Julius Dommer',
      'Montage: Julius Dommer, Rita Schwarze',
      'Schnittassistenz, Vorschnitt: Arturo Peña Quesada',
      'Ton: Karel Ducase Manzano, Marcel Kunzman',
      'Dramaturgie: Rita Schwarze',
      'Sounddesign und Tonschnitt: Dora Tomic',
      'Colorgrading: Fabiana Cardalda',
      'Produktionsassistenz: Maria Elisa Pérez Leal',
      'Produktion: Kunsthochschule für Medien Köln, Escuela Internacional de Cine y Televisión San Antonio de los Baños und Julius Dommer',
      'Unterstützung: Freunde der KHM e. V.'],
    cast: [],
    media: ['../assets/La CEN 0.jpg', '../assets/LA_CEN_STILL1.jpg', '../assets/LA_CEN_STILL2.jpg', '../assets/LA_CEN_STILL3.jpg', '../assets/LA_CEN_STILL4.jpg']
  },
  {
    id: 1,
    title: 'ASCONA',
    subtitle: '(Dokumentarfilm, 2019, 15:00 Min, 16mm to HD, Color + B&W, Dolby SR)',
    description: '',
    synopsis: 'ASCONA beschäftigt sich mit einem Ort, der aus der Zeit gefallen scheint, sich seit den' +
      '1950er Jahren nicht verändert hat, aber immer noch existiert. Ein Minigolfplatz wird zu' +
      'einer Analogie einer Gesellschaftsanalyse. Den Konservatismus der 1950er Jahren' +
      'reflektierend, zeigt ASCONA Protagonisten, die von den Anfängen und Schwierigkeiten' +
      'des Platzes und des Sports zwischen Freizeitspaß und Spitzensport mit trockenem' +
      'norddeutschem Witz berichten und einen Querschnitt der Gesellschaft repräsentieren.',
    awards: ['Kurzfilmtage Oberhausen 2019',
      'Filmfest Weiterstadt 2019',
      'Nordische Filmtage Lübeck 2019 (Preis für den besten Kurzfilm)',
      'KFFK Kölner Kurzfilmfestival 2019',
      'blicke. Filmfestival des Ruhrgebiets 2019',
      'Bamberger Kurzfilmtage 2020',
      'Stranger Than Fiction Dokumentarfilmfest 2020',
      'Clermont-Ferrand 2020 (Soirée Allemande)'],
    staff: ['Buch / Regie / Bildgestaltung: Julius Dommer',
      'Montage: Julius Dommer',
      'Montage / Dramaturgie: Rita Schwarze',
      'Regie Assistenz/ künstl. Beratung: Miriam Gossing',
      'Kamera Assistenz: Christian Kochmann',
      'Ton: Tim Gorinski, Christoph Krüger, Felix Bartke, Arne Lange',
      'Sounddesign: Tim Gorinski',
      'Tonbearbeitung / Tonmischung: Ralf Schipke, Henning Hein',
      'Farbkorrektur: Fabiana Cardalda',
      'Produktion: Julius Dommer / Kunsthochschule für Medien Köln'],
    cast: [],
    media: ['../assets/Ascona_0.jpg', '../assets/ASCONA_STILL_1.jpg', '../assets/ASCONA_STILL_2.jpg', '../assets/ASCONA_STILL_3.jpg', '../assets/ASCONA_STILL_4.jpg']
  },
  {
    id: 2,
    title: 'Rebar',
    subtitle: '(Dokumentarfilm, 2017, Full HD, Colour, Stereo, 29:28 Minuten)',
    description: '',
    awards: ['Stranger than Fiction 2017',
      'Sehsüchte Filmfestival 2017',
      'Minsk international Film Festival Listapad 2017',
      'Wendland Shorts 2018 (Silberner Storch für den besten Kurz-Dokumentarfilm)',
      'Blicke Filmfestival 2018 (Ausblicke Preis)'],
    synopsis: 'Zahlen sind für Rebar das Größte. In diesem Dokumentarfilm lernen wir die Realität eines Autisten kennen, der sich mit seiner ehrlichen Art und einer Geschwindigkeit von 30 km/h in die Herzen der Zuschauer katapultiert.',
    staff: ['Buch, Regie, Bildgestaltung, Montage: Julius Dommer',
      'Schnittbetreuung: Carina Mergens',
      'Titelanimation: Martin Scharf',
      'Tonmischung: Ralf Schippke',
      'Produktion: Julius Dommer / Kunsthochschule für Medien Köln'],
    cast: [],
    media: ['../assets/Rebar_0.jpg', '../assets/still.01_Rebar.jpg', '../assets/still.02_Rebar.jpg']
  },
  {
    id: 3,
    title: 'Berta',
    subtitle: '(Dokumentarfilm, 2017, 16mm to HD, Colour, Stereo, 07:49 Minuten)',
    description: '',
    awards: ['18.Dresdner Schmalfilmtage 2017 (Jury-Preis)',
      'Kurzfilmtage Oberhausen 2017',
      'Backup_Festival Kurzfilmfestival Weimar 2017',
      'Open Eyes Filmfeset Marburg 2017 (3. Platz Publikumspreis)',
      'Interfilm Berlin 2017 (Greenfilm Online Award)',
      'exground filmfest Wiesbaden, 2017',
      'Internationales Filmfest Braunschweig 2017',
      'Bamberger Kurzfilmtage 2018',
      'International Documentary Film Festival „Okiem Młodych” 2018'],
    synopsis: 'Der Hunger nach Fleisch wächst in unserer Gesellschaft. Die Preise dagegen sinken. "Berta" zeigt, dass es auch noch kleine Familienbetriebe für Fleischverarbeitung gibt, die mit Massentierhaltung und Großschlachtereien nichts gemein haben.',
    staff: ['Buch, Regie, Bildgestaltung, Montage: Julius Dommer',
      'Kameraassistent: Alex Schwarz',
      'Ton: Bilal Bahadir',
      'Farbkorrektur: Ewald Hentze',
      'Tonmischung: Judith Nordbrock',
      'Produktion: Julius Dommer / Kunsthochschule für Medien Köln'],
    cast: [],
    media: ['../assets/Berta Still 0.jpg', '../assets/Berta Still 1.jpg', '../assets/Berta Still 2.jpg', '../assets/Berta Still 3.jpg', '../assets/Berta Still 4.jpg']
  },
  {
    id: 4,
    title: 'Integration',
    subtitle: '(Social Spot, 2017, 2K, Colour,  Dolby SR, 1:03 Minuten)',
    description: '',
    awards: [],
    synopsis: 'Eine Stammtischgruppe in Köln integriert einen Geflüchteten.. vermeintlich gut, vielleicht zu gut.' +
      'Ausgestrahlt als Vorfilm in Kölner Kinos im Rahmen der Kampagne "Du bes Kölle - Arsch huh"',
    staff: ['Buch, Regie, Schnitt: Julius Dommer',
      'Bildgestaltung: Christian Mario Löhr',
      'Maskenbildner: Robert Schmermer',
      'Ton: Tarik Badaoui'],
    cast: ['Warsama Guled',
      'Peter Brachschoss',
      'Thorsten Kai Botenbender'],
    media: ['../assets/Integration_0.jpg'],
    vimeoLink: 'https://player.vimeo.com/video/202976264'
  },
  {
    id: 5,
    title: 'Fleisch',
    subtitle: '(Social Spot, 2016, 2K, Dolby SR, 1:07 Minute)',
    description: '',
    awards: ['Très Court Filmfestival Paris 2017',
      'Cine Jardim Filmfestival Brazil 2017'],
    synopsis: 'Ein Schlachtereibetrieb, überarbeitete Werkarbeiter, fragwürdige Arbeitsbedingungen, ein Unfall, ' +
      'schlechte medizinische Versorgung… Blut und noch mehr Blut.' +
      'Ein satirischer SocialSpot für die Initiative „Land der fairen Arbeit. NRW“',
    staff: ['Buch, Regie, Schnitt: Julius Dommer',
      'Bildgestaltung: Christian Mario Löhr',
      '1. Kameraassistenz: Marie Zahir',
      'Oberbeleuchter: Alexander Schwarz',
      'Maske: Jenny Uhlig',
      'SFX Maske: Robert Schmermer',
      'Ton: Christoph Schilling',
      'Sounddesign: Jan Esperer',
      'Produktionsleitung: Maike Drinhausen',
      'Produktion: Julius Dommer/ Kunsthochschule für Medien Köln'],
    cast: ['Ali Murtaza'],
    media: ['../assets/Fleisch_0.jpg'],
    vimeoLink: 'https://player.vimeo.com/video/172920840'
  },
  {
    id: 6,
    title: 'Königswinter',
    subtitle: '(Dokumentarfilm, 2016, Full HD, Colour, Stereo, 07:32 Minuten)',
    description: '',
    awards: ['KFFK Köln 2016'],
    synopsis: 'Ein Rudiment aus längst vergangenen Tagen; eine Arbeit, die so eigentlich durch die Technisierung nicht mehr existiert.' +
      'Doch in einem kleinen Ort am Siebengebirge findet man ihn noch: Den Schrankenwärter in Königswinter',
    staff: ['Buch, Regie, Bildgestaltung, Montage: Julius Dommer',
      'Ton: Christoph Krüger',
      'Tonmischung: Judith Nordbrock',
      'Produktion: Julius Dommer / Kunsthochschule für Medien Köln'],
    cast: [],
    media: ['../assets/koenigswinter_0.jpg', '../assets/koenigswinter_0.jpg'],
  }
];
