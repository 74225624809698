import {Component, OnInit} from '@angular/core';
import {ProductService} from '../product.service';
import {Product} from '../product';
import {MediaObserver} from '@angular/flex-layout';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  public routerLinkVariable = ''; // the value of the variable is string!
  public nbCols = 2;
  private media;
  products: Product[];
  selectedProduct: Product;

  onSelect(product: Product): void {
    this.selectedProduct = product;
  }

  constructor(private productService: ProductService, media: MediaObserver) {
    this.products = productService.getProducts();
    this.media = media;
    this.updateGrids();
    this.media.asObservable().subscribe(() => {
      this.updateGrids();
    });
  }

  private updateGrids(): void {
    this.nbCols = (this.media.isActive('xl') || this.media.isActive('lg')) ? 2 : 1;
  }

  getProducts(): void {
    this.products = this.productService.getProducts();
  }

  ngOnInit(): void {
    this.getProducts();
  }


}
