import {Component, OnInit} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';

@Component({
  selector: 'app-vita',
  templateUrl: './vita.component.html',
  styleUrls: ['./vita.component.css']
})
export class VitaComponent implements OnInit {
  public nbCols = 2;
  private media;

  constructor(media: MediaObserver) {
    this.media = media;
    this.updateGrids();
    this.media.asObservable().subscribe(() => {
      this.updateGrids();
    });
  }

  private updateGrids(): void {
    this.nbCols = (this.media.isActive('xl') || this.media.isActive('lg')) ? 2 : 1;
  }

  ngOnInit(): void {
  }

}
