import {Component, OnInit} from '@angular/core';
import {Product} from '../product';
import {ProductService} from '../product.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize} from 'ngx-gallery-9';
import {faTimesCircle, faArrowsAlt} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  product: Product;
  startIndex: number;
  trustedURL: SafeHtml;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private location: Location, private sanitizer: DomSanitizer) {
    this.startIndex = 0;
    this.galleryImages = [];
  }

  ngOnInit(): void {
    this.getProduct();
    if (this.product.vimeoLink) {
      this.trustedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.vimeoLink);
    }

    for (let i = 1; i < this.product.media.length; i++) {
      this.galleryImages.push(
        {
          small: String(this.product.media[i]),
          medium: String(this.product.media[i]),
          big: String(this.product.media[i])
        }
      );
    }

    this.galleryOptions = [
      {
        width: '100%',
        height: '1200px',
        thumbnailsColumns: 0,
        fullscreenIcon: 'fa fa-arrows-alt',
        imageAnimation: NgxGalleryAnimation.Slide,
        fullWidth: true,
        imageAutoPlay: true,
        imageAutoPlayInterval: 6000,
        previewCloseOnEsc: true,
        previewAutoPlay: true,
        closeIcon: 'fa fa-times-circle',
        previewCloseOnClick: true,
        imageSize: NgxGalleryImageSize.Contain
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }

  getProduct(): void {
    const title = this.route.snapshot.paramMap.get('title');
    this.productService.getProductByTitle(title).subscribe(product => this.product = product);
  }

  goBack(): void {
    this.location.back();
  }

}
