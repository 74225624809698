<div class="content">
  <mat-grid-list cols="{{nbCols}}" rowHeight="6:4" gutterSize="0px">
    <mat-grid-tile>
      <img mat-card-image src="../../assets/we.jpg" style="    width: 30%;">
    </mat-grid-tile>
    <mat-grid-tile>
      <div >
      <p style="width: 40%; color: rgb(79, 79, 79);font-family:  Helvetica, Arial, sans-serif;font-size: 13px;font-weight: bold;">Filmemacher / Regisseur</p>
      <br>
      <p style="width: 40%;color: rgb(79, 79, 79);font-family:  Helvetica, Arial, sans-serif;font-size: 13px;">
        Julius Dommer, geboren und aufgewachsen in Schleswig- Holstein,
        studierte von 2012 bis 2018 grundständig an der Kunsthochschule für Medien Köln mit Schwerpunkt in
        Dokumentarfilm und Kamera.</p>
        <p style="width: 40%;color: rgb(79, 79, 79);font-family: Helvetica, Arial, sans-serif;font-size: 13px;">
        Seit 2014 Tutor im Bereich Filmmontage / Postproduktion.

        Von 2015 bis 2016 als Stipendiat an der internationalen Filmschule EICTV Kuba (Dokumentarfilm-Klasse).
        </p>
        <p style="width: 40%;color: rgb(79, 79, 79);font-family: Helvetica, Arial, sans-serif;font-size: 13px;">
        Seine Dokumentarfilme liefen auf zahlreichen nationalen und internationalen Festivals. "Berta" gewann u.a. den
        Jury Preis bei den Dresdner Schmalfilmtagen 2017 und den Greenfilm Online Award 2017 bei Interfilm Berlin. Sein
        Dokumentarfilm "Rebar" gewann bei den Wendlandshorts 2018 und beim Blicke Filmfestival 2018. Im Rahmen des
        Programms "Kurz und gut" wurde "Rebar" im September 2018 im WDR ausgestrahlt.</p>
        </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
