import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {FlexLayoutModule} from '@angular/flex-layout';

import {AppComponent} from './app.component';
import {ProductsComponent} from './products/products.component';
import {VitaComponent} from './vita/vita.component';
import {AppRoutingModule} from './app-routing.module';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {FormsModule} from '@angular/forms';
import { NgxGalleryModule} from 'ngx-gallery-9';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  exports: [MatGridListModule],
  declarations: [
    AppComponent,
    ProductsComponent,
    VitaComponent,
    ProductDetailComponent,
  ],
  imports: [
    BrowserModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    RouterModule,
    MatListModule,
    FlexLayoutModule,
    AppRoutingModule,
    FormsModule,
    NgxGalleryModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
