<div class="content" *ngIf="product">
  <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="goBack()" mat-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <br>
  <div class="video" *ngIf="product.vimeoLink; else galleryBlock">
    <iframe [src]="trustedURL" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </div>
  <ng-template #galleryBlock>
    <div class="gallery">
      <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
    </div>
  </ng-template>
  <div class="contentDescription">
    <p
      style="letter-spacing: 2px; font-size: 16px; font-family:'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;">{{product.title|uppercase}}</p>
    <br>
    <p class="bold">{{product.subtitle}}</p>
    <br>
    <h3>Synopsis:</h3>
    <br>
    <p>{{product.synopsis}}</p>
    <br>
    <div *ngIf="product.cast?.length > 0">
      <h3>Cast:</h3>
      <br>
      <ul *ngFor="let castee of product.cast">
        <li>{{castee}}</li>
      </ul>
    </div>
    <br>
    <div *ngIf="product.staff?.length > 0">
      <h3>Stab:</h3>
      <br>
      <ul *ngFor="let stafee of product.staff">
        <li>{{stafee}}</li>
      </ul>
    </div>
    <br>
    <div *ngIf="product.awards?.length > 0">
      <h3>Festivals und Preise:</h3>
      <br>
      <ul *ngFor="let award of product.awards">
        <li>{{award}}</li>
      </ul>
    </div>
    <br>
  </div>
</div>
