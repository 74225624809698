<mat-toolbar class="navbar" color="accent">
  <div><h1
    style="font-family: sans-serif; font-size: 24px; color: rgb(0, 0, 0); letter-spacing: 2.5px; text-transform: uppercase; font-weight: normal;"> Julius Dommer</h1>
  </div>
  <div
    style="font-family:  sans-serif; font-size: 24px;letter-spacing: 2px;text-transform: uppercase;color: rgb(0, 0, 0);font-weight: normal; text-decoration: none; cursor: pointer;list-style: none;vertical-align: baseline;">
    <span style="padding-right: 150px">
      <a [routerLink]="['/vita']">Kontakt/Vita</a>
    </span>
    <span>
      <a [routerLink]="['/']">Filme</a>
    </span>
  </div>
</mat-toolbar>

<router-outlet></router-outlet>
